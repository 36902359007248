import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
//import List from '@mui/material/List';
//import Button from "@material-ui/core/Button"
import ServiceCard from "./service-card"
import Bar from "./bar"
const Services = () => {
  let services = [
    {
      id: 1,
      image: "details-info-icon.png",
      title: "Detail Info",
      description:"Contact us:",
      contact: "contact@somaliland-ehealth.com"
    },
    {
      id: 2,
      image: "consultation-icon.png",
      title: "Mailing Address",
      description:
        "PO Box 42071 - EDMONTON RPO MILLBOURNE, AB, T6K 4C4",
    },
    {
      id: 3,
      image: "search-doctor-icon.png",
      title: "Physical Address",
      description:
        "10575 114 St NW, Edmonton, AB T5H 3J6",
    },
    // {
    //   id: 2,
    //   image: "online-pharmacy-icon.png",
    //   title: "Online Pharmacy",
    //   description:
    //     "Buy your medicines with our mobile application with a simple delivery system.",
    // },
 
   
    // {
    //   id: 5,
    //   image: "emergency-care-icon.png",
    //   title: "Emergency Care",
    //   description:
    //     "You can get 24/7 urgent care for yourself or your children and your lovely family.",
    // },
    // {
    //   id: 6,
    //   image: "tracking-icon.png",
    //   title: "Tracking",
    //   description: "Track and save your medical history and health data.",
    // },
  ];

  const missionStatement = [
    {
      id: 1,
      data: "Improve diagnostic methods to decrease patients’ need to seek healthcare outside of Somaliland, where many patients’ remains are brought in body bags back into the country"
    },
    {
      id: 2,
      data: "To build a trusting relationship between the patients and their care providers"
    },
    {
      id: 3,
      data: "To provide seminars and training in a specific area of health for Somaliland healthcare providers through virtual meetings with Northern America Healthcare Providers"
    },
    {
      id: 4,
      data: "To provide public awareness of health education, e.g., to seek early medical attention and to advocate for themselves/their loved ones."
    },
    {
      id: 5,
      data: "To encourage healthcare providers to give accurate information about the patient’s diagnosis and the pros &amp; cons of the treatment of patient to patient/relatives to make informed decisions"
    },
    {
      id: 6,
      data: "Healthcare providers to safely guide the patients’ privacy"
    },
    {
      id: 7,
      data: "To encourage healthcare providers to be committed to observing the ethics of their healthcare professions by treating patients according to their conditions’ needs"
    }
  ];

  return (
    <Grid
      container
      spacing={3}
      style={{
        textAlign: "center",
        minHeight: 200,
        padding: 10,
      }}
    >
      <Grid
        item
        xs={12}
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Typography variant="h5">Mission Statement</Typography>
        <Bar />
        <Typography variant="subtitle1" style={{ margin: 10 }} align='left'>
        This organization’s mission is to introduce a new paradigm for health care to Somaliland
Health care: one that addresses the needs of the whole person – body, mind, spirit,
and emotions and one that includes a variety of treatments ranging from traditions
to newer science-based modalities. <br/>
The organization envisions a wellness-based healthcare system that offers
effective strategies for proper diagnoses and disease prevention which emphasizes
living lives of meaning, purpose, balance, and health. This new paradigm includes
encouraging consultation among Somaliland Healthcare Professionals / non-
Somalilanders healthcare providers by approaching with open minding to new
ideas to deliver proper treatment to patients.
</Typography>
<Typography variant="subtitle1" style={{ margin: 10 }} align='left'>

Somaliland E-Health is determined to provide services to Somalilanders in Somaliland, which include:
<ol  style={{ listStyleType: "lower-alpha" }}>
{
missionStatement.map(d => {
  return (
    <li key={d.id}>{d.data}</li>
    )
  }
)}
</ol>
</Typography>
</Grid>

      {services.map(service => (
        <Grid item xs={12} sm={4} key={service.id}>
          <ServiceCard service={service} />
        </Grid>
      ))}

      {/* <Grid item xs={12}>
        <Button variant="outlined" color="primary" size="large">
          Learn More1
        </Button>
      </Grid> */}
    </Grid>
  )
}
export default Services
